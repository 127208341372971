export default [
  { header: 'Configuration' },
  {
    title: 'CV Management',
    route: 'admin-cv-page',
    icon: 'UserIcon',
  },
  { header: 'Configuration' },
  {
    title: 'Admin Users',
    route: 'admin-users-page',
    icon: 'FileIcon',
  },
  {
    title: 'Settings',
    route: 'settings-page',
    icon: 'ShieldIcon',
  },
 
  { header: 'Loyalty' },
  {
    title: 'Pending Approval',
    route: 'PendingApproval',
    icon: 'ShieldIcon',
  },
  {
    title: 'Loyalty History',
    route: 'LoyaltyHistory',
    icon: 'ShieldIcon',
  },

  { header: 'Radio' },
  {
    title: 'Radio Settings',
    route: 'RadioSettings',
    icon: 'ShieldIcon',
  },
  {
    title: 'Presenters',
    route: 'Presenters',
    icon: 'ShieldIcon',
  },
  {
    title: 'Programs',
    route: 'Programs',
    icon: 'ShieldIcon',
  },
  {
    title: 'Podcasts',
    route: 'Podcasts',
    icon: 'ShieldIcon',
  },
  {
    title: 'Schedule',
    route: 'Schedule',
    icon: 'ShieldIcon',
  },
]
